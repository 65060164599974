<script setup>
import { EventBus } from "~~/utils/eventBus"
const refSearchbox = ref();
const props = defineProps([
  "input",
]);
const handleTextInput = (e) => {
  const input = e.target.value;
  EventBus.emit("searchSelect", input);
  // this.$emit("searchSelect", input);
  // if (refSearchbox.value) {
  //   refSearchbox.value.focus()
  // }
}
const clearInput = () => {
  // this.$emit("searchSelect", "");
  EventBus.emit("searchSelect", "");
}
const goToSearchResults = () => {
  EventBus.emit("goto-Search");
}
const offFocus = EventBus.on("focus-searchbox", () => {
  if (refSearchbox.value) {
    refSearchbox.value.focus()
  }
})

onBeforeUnmount(() => {
  offFocus()
})

</script>

<template>
  <div class="search-box-main-container container">
    <h3 class="searcg-tag text-center text-black">{{ $t('products_looking_for') }}</h3>
    <div class="search-box-sub-container">
      <div class="search-box">
        <form class="form-header">
          <div class="input-group">
            <input autofocus ref="refSearchbox" type="text" class="form-control" clearable focused="input !== ''"
              label="Search products" placeholder="Ex: Disney Bag" :value="input" clearIcon="fas fa-times"
              @input="handleTextInput" />
            <button v-if="input.length > 1" @click.prevent="clearInput" class=" btn btn-clear" aria-label="Search-Button">
              <!-- <i class="fa-solid fa-circle-xmark"></i> -->
              x
            </button>
            <button class="btn btn-solid" @click.prevent="goToSearchResults" aria-label="Search-Button">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </form>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* .container {
  padding-top: 140px;
} */

.search-container {
  /* position: sticky;
  top: 50px;
  background-color: #f2f2f2;
  padding: 10px;
  z-index: 1; */
  border-radius: 6px;
}

.remove-search-btn {
  font-size: 16px;
  font-weight: 700;
}

.btn-clear {
  position: absolute;
  top: 15px;
  right: 100px;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  z-index: 99999;
  color: var(--theme-deafult);
  font-size: medium;
}

/* @media (max-width: 600px) {

  .btn-clear {
    top: 8px;
    right: 50px;
  }
} */
</style>
