<script setup>
import SearchBox from "../components/SearchBox.vue";
import { useUtilityStore } from "../store/UtililityStore";
import { useCartStore } from "../store/CartStore";
import { useProductStore } from "../store/ProductStore";
import productBox1 from "./product-box/product-box1.vue";
import { useWindowScroll } from "@vueuse/core";
import { ref, onMounted } from "vue";
import { OnClickOutside } from "@vueuse/components/index";
const router = useRouter();
const props = defineProps(["range"]);
const utilityStore = useUtilityStore();
const cartStore = useCartStore();
const productStore = useProductStore();
const sessionData = computed(() => cartStore.sessionData);
const { y } = useWindowScroll({ behavior: 'smooth' });
const selectedLanguage = computed(() => utilityStore.selectedLanguage);
const products = computed(() => productStore.products);
const backOrders = computed(() => productStore.backOrders);
const pending = computed(() => productStore.pending);
const filterInput = ref("");
const timeoutId = ref();
const { range } = toRefs(props);
const startRowIndex = ref(1);
const localePath = useLocalePath();

const state = reactive({
  loadingCart: {},
  selectedSort: [
    {
      Created: {
        order: "desc",
      },
    },
    {
      Updated: {
        order: "desc",
      },
    },
  ],
});

const getProducts = async () => {
  if (!filterInput.value) {
    productStore.resetProducts();
    return;
  }
  await productStore.fetchProducts({
    sortOrder: state.selectedSort,
    filterInput: filterInput.value,
    range: range.value,
    startRowIndex: startRowIndex.value,
    selectedFilters: {},
    selectedLanguage: selectedLanguage.value,
    sessionData: sessionData.value,
  });
};

const handleInput = (value) => {
  clearTimeout(timeoutId.value);
  filterInput.value = value;
  getProducts();
  timeoutId.value = setTimeout(() => {
    if (y.value > 0) y.value = 0;
  }, 500);
};
const offHandlerInput = EventBus.on("searchSelect", handleInput);

const goToSearchResults = (event) => {
  event && event.preventDefault();
  router.push({
    path: localePath("/products/search"),
    query: {
      ...{
        ...(filterInput.value && { ...{ searchKeyword: filterInput.value } }),
      },
    },
  });
};
const close = () => {
  productStore.resetProducts();
};

const offGoToSearchResults = EventBus.on("goto-Search", goToSearchResults);

onBeforeUnmount(() => {
  productStore.resetProducts();
  filterInput.value = "";
});
onMounted(() => {
  productStore.resetProducts();
  filterInput.value = "";
});
onBeforeUnmount(() => {
  offGoToSearchResults();
  offHandlerInput();
});
</script>

<template>
  <div class="mini-search-container container" :class="{ position: y > 100 && y <= 600, smalls: y > 600 }">
    <OnClickOutside @trigger="close">
      <div class="mini-search" :class="{ position: y > 100 && y <= 600, smalls: y > 600 }">
        <SearchBox key="cld-search" @searchSelect="handleInput" :input="filterInput"
          @keydown.enter="goToSearchResults" />
      </div>
    </OnClickOutside>
  </div>
  <div id="content" class="">
    <div v-if="pending">
      <WidgetsLoader :loading="pending"> </WidgetsLoader>
    </div>
    <div id="products" v-else>
      <div class="row d-flex justify-content-center align-items-center" :v-if="products && products.length">
        <div v-for="(item, i) in products" :key="i" class="col mx-2 my-2">
          <div class="product-box">
            <productBox1 :item="item" :index="i" :backOrders="backOrders" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.product-box {
  border: 2px solid #b7b8b9;
  width: 220px;
  /* height: 390px; */
}

.product-box:hover {
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.3);
  border: 2px solid var(--theme-deafult);
}

/* @media (max-width: 767.5px) {
  #filterbar {
    width: 50%;
  }
}

@media (max-width: 525.5px) {
  #filterbar {
    float: none;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 5px;
  }

  #content.my-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .col-lg-4,
  .col-md-6 {
    padding-left: 0;
  }
}

@media (max-width: 500.5px) {
  .pagination {
    display: none;
  }
} */

.col-md-8,
.col-md-2 {
  padding: 0 !important;
}



.mini-search-container.small {
  top: 10px;
  right: 0;
  transform: scale(0.5);
  animation: mini-search-shrink 0.5s ease-in-out;
  max-width: 100%;
  z-index: 9999;
  margin: 0;
  width: 100%;
}

@keyframes mini-search-shrink {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.9);
  }
}

.mini-search {
  /* width: 800px; */
  padding: 0;
}

/* .mini-search.small {
  top: 10px;
  left: 80%;
  animation: mini-search-shrink-6c930341 0.2s ease-in-out;
  z-index: 9999;
  margin: 0;
  position: absolute;
  width: 40%;
} */



.mini-search-container.position {
  position: fixed;
  top: 67px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  transition: transform 0.5s ease-in-out;
  width: 60%;
}

.mini-search-container.smalls {
  position: fixed;
  top: 67px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  transition: transform 0.7s ease-in-out;
  width: 60%;
  height: 10px;
  opacity: 0.6;
}
</style>
